//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import {adminCodes} from "../../../../../MultiSiteClient/enums/adminSettings";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "user-login",
  components: {
    LabeledControlLayout
  },
  data() {
    return {
      visibleForm: 'signIn',
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'This is a required field!',
          (v) =>
              /.+@.+\..+/.test(v) ||
              this.$t('Please enter a valid email address!')
        ],
        rules: {
          required: (value) => !!value || 'This is a required field!',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters!',
        },
        valid: false,
        rememberMe: false,
      },
      currentUserName: '',
      currentUserID: 0,
      redirectTo: '/configurator'
    }
  },
  methods: {
    ...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    ...mapActions('common/cache', ['loadingCache']),
    ...mapActions('rememberedUser', ['saveUser','removeSavedUser']),
    ...mapGetters('rememberedUser',['getSavedUserInfo']),
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const changePasswordResponse = await this.$axios.$post(
              `/auth/portal-login-check-must-change-password`,
              {
                username: this.signInForm.data.email,
                password: this.signInForm.data.password,
              }
          )
          if(changePasswordResponse) {
            if (changePasswordResponse.hasErrors && changePasswordResponse.errorMessages.error) {
              this.$toast.error(changePasswordResponse.errorMessages.error)
            } 
            await this.localLoginAndRedirectHandler(this.signInForm.data.email, this.signInForm.data.password)
          }
        } catch (err) {
          this.$toast.error('Error while logging in!')
        }
      }
    },
    async localLoginAndRedirectHandler(email, password, checkForRememberedUser = true) {
      try {
        const response = await this.$auth.loginWith('local', {
          data: {
            username: email,
            password: password,
          },
        })
        if (response.status === 200 && response.data.token) {
          if(checkForRememberedUser && this.signInForm.rememberMe) {
            this.saveUser({email: email, password: password})
          }
          this.$toast.success('Logged in!')
          await this.loadingCache()

          if (this.$route.query && this.$route.query.redirect) {
            await this.$router.push('/' + this.$route.query.redirect)
            return
          }
          const redirectUrl = (await this.fetchAdminSettingByCode(adminCodes.REDIRECT_AFTER_FRONTEND_LOGIN))?.value

          const redirectTarget = redirectUrl || this.redirectTo;
          if (redirectTarget.includes('configurator')) {
            window.location = redirectTarget
          }
          else {
            await this.$router.push(redirectTarget)
          }

        } else {
          this.$toast.error('Invalid username or password!')
        }
      }catch (e) {
      }
    },
  }
}
